import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Carousel, Icon, Image, Layout, Price, SEO } from 'components';
import { classNames, loop } from 'helpers';
import { Slices } from '../slices/slices';
import { CartConsumer } from '../context/cart';
import './product.scss';

export const Product = props => {
  const { location, data } = props;
  const { shopifyProduct: product, prismicProduct, site } = data;
  const { title, images, availableForSale, variants, metafields, descriptionHtml, shopifyId, priceRange } = product;
  const productExtra = (prismicProduct && prismicProduct.data) || {};
  const productExtraSlices = productExtra && productExtra.body;
  const productTerms = productExtra && productExtra.productTerms;
  const { siteName, defaultTitle, defaultDescription } = site.siteMetadata;

  const [slideObj, setSliderObj] = useState({});

  // Format SEO Ojbect
  const seoTitle = metafields.find(metafield => metafield.key === 'title_tag');
  const seoDescription = metafields.find(metafield => metafield.key === 'description_tag');
  const reviews = metafields.find(metafield => metafield.key === 'reviews');
  const pageTitle = seoTitle && seoTitle.value;
  const pageDesc = seoDescription && seoDescription.value;

  // Current Product or Product Option
  const hasVariants = variants && variants.length > 1;
  const variantInitial = variants && variants[0] && variants[0].shopifyId;
  const [selected, setSelected] = useState(variantInitial);
  const variantSelected = variants.find(variant => variant.shopifyId === selected);
  const variantSelectedImage = variantSelected && variantSelected.image && variantSelected.image.id;
  const variantSelectedImageIndex =
    variantSelectedImage && images.findIndex(image => image.id === variantSelectedImage);
  const variantAvailable = variantSelected && variantSelected.availableForSale;

  // Schema items
  const { amount: priceMin, currencyCode } = priceRange.minVariantPrice;

  useEffect(() => {
    if (slideObj.current) slideObj.current.slideTo(variantSelectedImageIndex);
  }, [variantSelectedImageIndex]);

  const scrollToTop = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollPosition > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, scrollPosition - scrollPosition / 8);
    }
  };

  const addToCartHandler = (event, addToCart, variantId) => {
    event.preventDefault();
    addToCart(variantId, 1);
    scrollToTop();
  };

  const schemaOrgProduct = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    sku: shopifyId,
    name: title || defaultTitle,
    description: pageDesc || defaultDescription,
    image: images.map(image => image.originalSrc),
    brand: {
      '@type': 'Thing',
      name: siteName,
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: currencyCode,
      price: priceMin,
      availability: availableForSale ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock',
    },
  };

  return (
    <Layout location={location}>
      <CartConsumer>
        {cart => {
          const { addToCart, cartLoading } = cart;
          return (
            <div className="product-page">
              <SEO title={pageTitle} desc={pageDesc} />
              <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaOrgProduct)}</script>
              </Helmet>
              <div className="product-page-header">
                <div className="wrapper">
                  <div className="product-page-images">
                    {images && (
                      <Carousel
                        buttonsDisabled
                        sliderRef={ref => setSliderObj(ref)}
                        // slideToIndex={variantSelectedImage}
                      >
                        {images.map(image => (
                          <div key={image.id}>
                            <Image image={image} alt={title} />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>
                  <div className="product-page-info">
                    <h1 className="product-page-title">{title}</h1>
                    <div className="product-page-price-container">
                      {variantSelected && (
                        <div className="product-page-price">
                          <Price amount={variantSelected.price} />
                        </div>
                      )}
                      {reviews && <div dangerouslySetInnerHTML={{ __html: reviews.value }} />}
                    </div>
                    {(productExtra.description || descriptionHtml) && (
                      <div
                        className="product-page-description"
                        dangerouslySetInnerHTML={{
                          __html: (productExtra.description && productExtra.description.html) || descriptionHtml,
                        }}
                      />
                    )}
                    <div className="product-page-form">
                      {hasVariants && (
                        <div className="product-page-form-options">
                          <div className="product-page-form-title">Buying Options</div>
                          <div className="product-page-form-variations">
                            {loop(variants, variant => (
                              <div
                                key={variant.shopifyId}
                                className={classNames('product-page-form-variation', {
                                  selected: selected === variant.shopifyId,
                                })}
                                onClick={() => setSelected(variant.shopifyId)}
                              >
                                <div className="product-variant">
                                  <div className="product-variant-title">{variant.title}</div>
                                  <div className="product-variant-price">
                                    {variant.availableForSale ? (
                                      <Price amount={variant.price} />
                                    ) : (
                                      <div className="product-variant-off">OUT OF STOCK</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="product-action">
                        {cartLoading ? (
                          <a
                            href="#add"
                            className="button secondary product-add-cart"
                            onClick={event => event.preventDefault()}
                          >
                            Please wait...
                          </a>
                        ) : (
                          <a
                            href="#add"
                            className={classNames('button secondary product-add-cart', { out: !variantAvailable })}
                            onClick={event => variantAvailable && addToCartHandler(event, addToCart, selected)}
                          >
                            {variantAvailable ? 'Add to Cart' : 'This product is out of stock'}
                          </a>
                        )}
                      </div>

                      <div className="product-page-terms">
                        {loop(productTerms, productTerm => (
                          <div key={productTerm.key} className="product-page-term">
                            <Icon className="product-page-term-icon" title="CheckPrimary" />
                            {productTerm.term && productTerm.term.text}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </CartConsumer>
      <Slices data={productExtraSlices} />
    </Layout>
  );
};

export default Product;

export const pageQuery = graphql`
  query($handle: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteName
      }
    }
    prismicProduct(uid: { eq: $handle }) {
      data {
        title: product_title {
          text
        }
        productTerms: product_terms {
          term: product_term {
            text
          }
        }
        description: product_description {
          html
        }
        body {
          ...ProductFaq
          ...ProductFeatures
          ...ProductSlider
          ...ProductBox
          ...ProductVideo
          ...ProductProductReview
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      shopifyId
      title
      availableForSale
      descriptionHtml
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants {
        shopifyId
        title
        price
        availableForSale
        image {
          id
        }
      }
      metafields {
        value
        key
      }
      images {
        id
        originalSrc
        localFile {
          childImageSharp {
            fluid(maxWidth: 910, maxHeight: 910) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
